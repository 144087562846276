.floating-login-btn {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
}

.floating-back-btn {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1000;
}

.main {
  width: 100vw;
  height: calc(100vh - 16rem);
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main .center-me {
  max-width: 512px;
  margin: 0 auto;
}

.floatingToast {
  background: var(--bs-success);
  color: var(--bs-light);
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.alignIconWithText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.masthead {
  width: 128px;
  height: auto;
}
